var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "form" }, [
    _c("h2", { staticClass: "form__heading" }, [
      _vm._v("Select type of company"),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form__button-group form__button-group--full-width" },
      [
        _c("fs-button-main", { attrs: { to: { name: "quotationForm" } } }, [
          _vm._v("Buyer"),
        ]),
        _vm._v(" "),
        _vm.$store.user.loggedIn
          ? _c("fs-button-main", { attrs: { to: { name: "supplierAdd" } } }, [
              _vm._v("Supplier"),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.$store.user.loggedIn
          ? _c(
              "fs-button-main",
              { attrs: { to: { name: "supplierRegister" } } },
              [_vm._v("Supplier")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form__group form__group--message form__group--center" },
      [
        _c("p", { staticClass: "form__subheading" }, [
          _vm._v("Select type of company you want to add."),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }