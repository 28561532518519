<template>
  <section class="form">
    <h2 class="form__heading">Select type of company</h2>
    <div class="form__group form__group--message form__group--center">
      <p class="form__subheading">Select type of company you want to add.</p>
    </div>
    <div class="form__button-group form__button-group--full-width">
      <fs-button-main :to="{ name: 'quotationForm' }">Buyer</fs-button-main>
      <fs-button-main :to="{ name: 'supplierAdd' }" v-if="$store.user.loggedIn">Supplier</fs-button-main>
      <fs-button-main :to="{ name: 'supplierRegister' }" v-if="!$store.user.loggedIn">Supplier</fs-button-main>
    </div>
  </section>
</template>

<script>
import FsButtonMain from '@components/buttons/fs-button-main.vue';

export default {
  components: { FsButtonMain },
  load({ store, route }) {
    store.head.title = `FindSourcing – Add company`;
  },
  data() {
    return {};
  },
  methods: {
    decline() {
      if (this.$route.query.declined) {
        this.$notify({
          type: 'error',
          title: 'Permission declined',
        });
      }
    },
  },
  mounted() {
    window.addEventListener('load', this.decline);

    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (e) {}
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.decline);

    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (e) {}
  },
};
</script>

<style lang="scss">
@use 'sass:math';
</style>
